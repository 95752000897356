import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React from "react";
import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import "./assets/css/index.css";
import PrivateRoute from "./components/authentication/PrivateRoute.tsx";
import PublicRoute from "./components/authentication/PublicRoute.tsx";
import RouterError from "./components/authentication/RouterError.tsx";
import LayoutContainer from "./components/layout/LayoutContainer.tsx";
import { initializeFirebase } from "./firebase.tsx";
import AppointmentBooking from "./pages/appointments/AppointmentBooking.tsx";
import AppointmentConfirmation from "./pages/appointments/AppointmentConfirmation.tsx";
import AppointmentForm from "./pages/appointments/AppointmentForm.tsx";
import AppointmentSchedule from "./pages/appointments/AppointmentSchedule.tsx";
import AppointmentSummary from "./pages/appointments/AppointmentSummary.tsx";
import Account from "./pages/authentication/Account.tsx";
import AuthHandler from "./pages/authentication/AuthHandler.tsx";
import GettingStarted from "./pages/authentication/GettingStarted.tsx";
import Locations from "./pages/locations/Locations.tsx";
import NewLocation from "./pages/locations/NewLocation.tsx";
import Overview from "./pages/locations/Overview.tsx";
import Payments from "./pages/locations/Payments.tsx";
import { initializeSentry } from "./sentry.tsx";

dayjs.extend(utc);
dayjs.extend(timezone);
initializeSentry();
initializeFirebase();

const router = createBrowserRouter([
  {
    element: <LayoutContainer />,
    errorElement: <RouterError />,
    children: [
      {
        path: "/",
        element: (
          <PublicRoute>
            <GettingStarted />
          </PublicRoute>
        ),
      },
      {
        path: "/account",
        element: (
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        ),
      },
      {
        path: "/auth/handler",
        element: <AuthHandler />,
      },
      {
        path: "/b/:locationId",
        element: <AppointmentBooking />,
      },
      {
        path: "/locations",
        element: (
          <PrivateRoute>
            <Locations />
          </PrivateRoute>
        ),
      },
      {
        path: "/locations/appointments/:locationId",
        element: (
          <PrivateRoute>
            <AppointmentSchedule />
          </PrivateRoute>
        ),
      },
      {
        path: "/locations/appointment-form/:locationId",
        element: (
          <PrivateRoute>
            <AppointmentForm />
          </PrivateRoute>
        ),
      },
      {
        path: "/locations/new",
        element: (
          <PrivateRoute>
            <NewLocation />
          </PrivateRoute>
        ),
      },
      {
        path: "/locations/overview/:locationId",
        element: (
          <PrivateRoute>
            <Overview />
          </PrivateRoute>
        ),
      },
      {
        path: "/locations/payments/:locationId",
        element: (
          <PrivateRoute>
            <Payments />
          </PrivateRoute>
        ),
      },
      {
        path: "/summary/:appointmentId",
        element: <AppointmentSummary />,
      },
      {
        path: "/confirmation/:appointmentId",
        element: <AppointmentConfirmation />,
      },
    ],
  },
]);

Modal.setAppElement("#root");

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
        style={{ width: "100%", maxWidth: "600px" }}
      />
    </QueryClientProvider>
  </React.StrictMode>,
);
