import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/Select";
import FormComponent from "@/models/forms/FormComponent";
import { FormComponentType } from "@/utils/Enums";
import { useEffect, useState } from "react";
import Label from "../ui/Label";
import ModalDialog from "../ui/ModalDialog";
import AddMultiSelectFormComponent from "./AddMultiSelectFormComponent";
import AddTextFieldFormComponent from "./AddTextFieldFormComponent";

const FormModalDialog = (props: {
  isModalOpen: boolean;
  setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
  component?: FormComponent;
  onSuccess: (component: FormComponent) => void;
}) => {
  const { isModalOpen, setIsModalOpen, component, onSuccess } = props;
  const [componentType, setComponentType] = useState(
    component?.properties.type || FormComponentType.TextField,
  );

  useEffect(() => {
    setComponentType(component?.properties.type || FormComponentType.TextField);
  }, [component]);

  return (
    <ModalDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div className="mb-2 text-4xl text-center text-monkey-purple">
        Add Component
      </div>
      <div>
        <Label>Component type</Label>
        <Select
          name="component-type"
          value={componentType}
          defaultValue={componentType}
          onValueChange={(value) =>
            setComponentType(value as FormComponentType)
          }
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Theme" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={FormComponentType.TextField}>
              Text Field
            </SelectItem>
            <SelectItem value={FormComponentType.MultiSelect}>
              MultiSelect
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
      {componentType === FormComponentType.TextField && (
        <AddTextFieldFormComponent
          component={component}
          onSuccess={onSuccess}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
      {componentType === FormComponentType.MultiSelect && (
        <AddMultiSelectFormComponent
          component={component}
          onSuccess={onSuccess}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
    </ModalDialog>
  );
};

export default FormModalDialog;
