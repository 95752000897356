import Appointment from "@/models/appointments/Appointment";
import { PaymentStatus } from "@/utils/Enums";
import dayjs from "dayjs";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";
import { v4 as uuidv4 } from "uuid";
import { app } from "../firebase";

const db = getFirestore(app);

export const createAppointment = async (appointment: Appointment) => {
  appointment.id = uuidv4();
  appointment.paymentStatus = PaymentStatus.Unpaid;
  appointment.created = dayjs(new Date()).tz(appointment.timeZone).toDate();
  await setDoc(doc(db, "appointments", appointment.id), appointment);
};

export const getAppointmentById = async (
  appointmentId: string,
): Promise<Appointment | null> => {
  const appointmentDoc = await getDoc(doc(db, "appointments", appointmentId));
  if (appointmentDoc.exists()) {
    return appointmentDoc.data() as Appointment;
  } else {
    console.error(`No appointment found with ID: ${appointmentId}`);
    return null;
  }
};

export const getAppointmentsByEmployeeId = async (
  employeeId: string,
): Promise<Appointment[]> => {
  const appointments = collection(db, "appointments");
  const querySnapshot = await getDocs(
    query(appointments, where("employee.id", "==", employeeId)),
  );
  const appointmentsArray = [] as Appointment[];
  querySnapshot.forEach((doc) => {
    appointmentsArray.push(doc.data() as Appointment);
  });
  return appointmentsArray;
};

export const updateAppointment = async (appointment: Appointment) => {
  await setDoc(doc(db, "appointments", appointment.id), appointment);
};

export const deleteAppointmentById = async (appointmentId: string) => {
  await deleteDoc(doc(db, "appointments", appointmentId));
};
