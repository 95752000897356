import React from "react";
import Label from "./Label";

const TextField = React.forwardRef(
  (
    props: {
      elementId: string;
      labelText: string;
      type?: string;
      value?: string;
      required?: boolean;
      className?: string;
      autoFocus?: boolean;
      defaultValue?: string;
      onInput?: (value: string) => void;
      onEnter?: (value: string) => void;
      submitOnEnter?: boolean;
    },
    ref: any,
  ) => {
    const {
      elementId,
      labelText,
      type,
      value,
      className,
      autoFocus,
      defaultValue,
      onInput,
      onEnter,
    } = props;
    const required = props.required === undefined ? false : props.required;
    const submitOnEnter =
      props.submitOnEnter === undefined ? false : props.submitOnEnter;
    return (
      <div className="w-full mb-5">
        <Label htmlFor={elementId} className="text-left">
          {required ? labelText + "*" : labelText}
        </Label>
        <input
          type={type || "text"}
          ref={ref}
          id={elementId}
          required={required}
          onInput={(e) =>
            onInput && onInput((e.target as HTMLInputElement).value)
          }
          onKeyDown={(e) => {
            e.key === "Enter" && !submitOnEnter && e.preventDefault();
            if (e.key === "Enter" && onEnter) {
              onEnter((e.target as HTMLInputElement).value);
            }
          }}
          value={value}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
          className={`block w-full h-10 p-2 text-base border-2 rounded-lg border-monkey-purple border:focus-monkey-purple focus:outline-none focus:ring-2 focus:ring-monkey-purple focus:border-monkey-purple ${className ?? ""}`.trim()}
        />
      </div>
    );
  },
);

export default TextField;
