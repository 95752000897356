import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";
import { v4 as uuidv4 } from "uuid";
import { app, auth } from "../firebase";
import Location from "../models/locations/Location";
import { uploadFileFromDataURL } from "./StorageService";
import { getUserById } from "./UserService";

const db = getFirestore(app);

export const getLocationById = async (locationId: string) => {
  const locationRef = doc(db, "locations", locationId);
  const locationSnapshot = await getDoc(locationRef);
  const location = locationSnapshot.data() as Location;
  return location;
};

export const getLocationsByUserEmail = async (
  email: string | null,
): Promise<Location[]> => {
  if (!email) {
    console.error("User email is undefined");
    return [];
  }
  const locationsRef = collection(db, "locations");
  const adminsQuery = query(
    locationsRef,
    where("admins", "array-contains", email),
  );
  const usersQuery = query(
    locationsRef,
    where("users", "array-contains", email),
  );
  const [adminsSnapshot, usersSnapshot] = await Promise.all([
    getDocs(adminsQuery),
    getDocs(usersQuery),
  ]);
  const locationsMap = new Map<string, Location>();
  adminsSnapshot.docs.forEach((doc) => {
    locationsMap.set(doc.id, { id: doc.id, ...doc.data() } as Location);
  });
  usersSnapshot.docs.forEach((doc) => {
    locationsMap.set(doc.id, { id: doc.id, ...doc.data() } as Location);
  });
  const locationsArray = Array.from(locationsMap.values());
  return locationsArray;
};

export const createLocation = async (location: Location) => {
  const user = await getUserById(auth?.currentUser?.uid);
  if (user?.stripeAccountId) {
    location.stripeAccountId = user.stripeAccountId;
  }
  const imageId = uuidv4();
  const imagePath = `/locations/${location.id}/${imageId}`;
  await updateLocation(location);
  if (location.image) {
    await uploadFileFromDataURL(imagePath, location.image);
    location.image = imagePath;
  }
  for (const employee of location.employees) {
    const imageId = uuidv4();
    const imagePath = `/locations/${location.id}/${imageId}`;
    if (employee.image) {
      await uploadFileFromDataURL(imagePath, employee.image);
      employee.image = imagePath;
    }
  }
  await updateLocation(location);
};

export const updateLocation = async (location: Location) => {
  await setDoc(doc(db, "locations", location.id), location);
};

export const deleteLocationById = async (locationId: string) => {
  await deleteDoc(doc(db, "locations", locationId));
};
