import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";

const storage = getStorage();

export const uploadFileFromDataURL = async (path: string, dataUrl: string) => {
  const storageRef = ref(storage, path);
  await uploadString(storageRef, dataUrl, "data_url");
};

export const getFileURL = async (path: string): Promise<string | null> => {
  if (!path) return null;
  const storageRef = ref(storage, path);
  const url = await getDownloadURL(storageRef);
  return url;
};
