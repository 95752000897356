import AppointmentDetail from "@/components/appointments/AppointmentDetails";
import NavigationContainer from "@/components/layout/NavigationContainer";
import LocationSidebar from "@/components/locations/LocationSidebar";
import Location from "@/models/locations/Location";
import LocationEmployee from "@/models/locations/LocationEmployee";
import { getAppointmentsByEmployeeId } from "@/services/AppointmentService";
import { getLocationById } from "@/services/LocationService";
import Store from "@/store";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import { useParams } from "react-router-dom";
import Event from "../../models/schedules/Event";

const AppointmentSchedule = () => {
  const { setIsLoading } = Store();
  const { locationId } = useParams();
  const localizer = dayjsLocalizer(dayjs);
  const setLocation = useState<Location | null>(null)[1];
  const setEmployee = useState<LocationEmployee | null>(null)[1];
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const location = await getLocationById(locationId!);
      setLocation(location);
      if (location.employees.length) {
        const employee = location.employees[0];
        setEmployee(employee);
        const appointments = await getAppointmentsByEmployeeId(employee.id);
        const events = appointments.map((appointment) => ({
          appointment: appointment,
          location: location,
          start: dayjs(appointment.date).toDate(),
          end: dayjs(appointment.date).add(1, "hour").toDate(),
          title: "Test Appointment",
        }));
        setEvents(events);
      }
    };
    fetchData().then(() => setIsLoading(false));
  }, [locationId, setEmployee, setLocation, setIsLoading]);

  return (
    <NavigationContainer sidebar={<LocationSidebar locationId={locationId!} />}>
      <div className="text-4xl text-monkey-purple">Appointments</div>
      {selectedEvent ? (
        <AppointmentDetail
          event={selectedEvent}
          cancel={() => setSelectedEvent(null)}
        />
      ) : (
        <Calendar
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          events={events}
          style={{ height: 500 }}
          onSelectEvent={(event) => setSelectedEvent(event)}
        />
      )}
    </NavigationContainer>
  );
};

export default AppointmentSchedule;
