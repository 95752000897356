import { User } from "firebase/auth";
import { create } from "zustand";

type Store = {
  // layout
  isLoading: boolean;
  sidebarVisible: boolean;
  sidebarCollapsed: boolean;
  setIsLoading: (isLoading: boolean) => void;
  toggleSidebar: () => void;
  collapseSidebar: () => void;
  uncollapseSidebar: () => void;
  setSidebarVisible: () => void;

  // auth
  user: User | null;
  setUser: (user: User | null) => void;
};

const Store = create<Store>((set) => ({
  // layout
  isLoading: false,
  sidebarVisible: false,
  sidebarCollapsed: false,
  setIsLoading: (isLoading) => set({ isLoading }),
  toggleSidebar: () =>
    set((state) => ({ sidebarCollapsed: !state.sidebarCollapsed })),
  collapseSidebar: () => set({ sidebarCollapsed: true }),
  uncollapseSidebar: () => set({ sidebarCollapsed: false }),
  setSidebarVisible: () =>
    set(() => ({
      sidebarVisible:
        document.getElementsByClassName("ps-sidebar-root").length > 0,
    })),

  // auth
  user: null,
  setUser: (user) => set({ user }),
}));

export default Store;
