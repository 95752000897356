export enum PaymentStatus {
  Paid = "paid",
  Unpaid = "unpaid",
  Refund = "refund",
  PartialRefund = "partial_refund",
  NoPaymentRequired = "no_payment_required",
}

export enum FormComponentType {
  TextField = "text_field",
  MultiSelect = "multi_select",
}
